body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.myMap {
  width: 100%;
  height: 100vh;
}

.leaflet-popup-content div {
  margin-bottom: 8px;
}

.leaflet-popup-content div b {
  display: block;
}

.inlineBx b {
  display: inline !important;
}

.inlineCus {
  display: inline !important;
}

/* path.leaflet-interactive {
  stroke: yellow !important;
  stroke-opacity: .1 !important;
  fill: #ffff0061 !important;
  fill-opacity: .1 !important;
} */

.dsd {
  display: flex;
}

.dsd .col6 {
  display: inline-block;
  white-space: nowrap;
  padding: 0 10px;
}

.px10 {
  padding: 0 10px;
}

.leaflet-popup-content {
  margin: 15px 10px 10px 10px !important;
  line-height: 1.3;
  font-size: 13px;
  font-size: 1.08333em;
  min-height: 1px;
  white-space: nowrap;
}

.col6 span {
  font-weight: 700;
  padding-top: 6px;
  display: block;
}

.leaflet-popup-content {
  width: auto !important;
}

.leaflet-container a.leaflet-popup-close-button {
  top: 10px !important;
  right: 10px !important;
  display: none !important;
}